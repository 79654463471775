import type { MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import {
	Field,
	type FieldValidatorType,
	type FormRenderProps,
} from "@progress/kendo-react-form";
import {
	type Dispatch,
	type SetStateAction,
	useEffect,
	useMemo,
	useState,
} from "react";
import { jobApi } from "../../../../../../../../../new/helpers";
import { BUSINESS_UNITS_FEATURE_KEY } from "../../../../../../../../common/models/src/lib/constants/business-unit.constants";
import { DRIVERS_FEATURE_KEY } from "../../../../../../../../common/models/src/lib/constants/driver.constants";
import { DEFAULT_PAGE_SIZE } from "../../../../../../../../common/models/src/lib/constants/grid.constants";
import { SUPPLIER_INVOICES } from "../../../../../../../../common/models/src/lib/constants/invoice.constants";
import {
	COLLECTION_LEG,
	COLLECT_DELIVER_LEG,
	DELIVERY_LEG,
} from "../../../../../../../../common/models/src/lib/constants/leg.constants";
import { ParentType } from "../../../../../../../../common/models/src/lib/enums/parent-type.enum";
import type { IBase } from "../../../../../../../../common/models/src/lib/interfaces/base.interface";
import type { IDriver } from "../../../../../../../../common/models/src/lib/interfaces/driver.interface";
import type { IOperationLeg } from "../../../../../../../../common/models/src/lib/interfaces/leg.interface";
import type { ILocation } from "../../../../../../../../common/models/src/lib/interfaces/location.interface";
import type { ISubcontractor } from "../../../../../../../../common/models/src/lib/interfaces/subcontractor.interface";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../../../../common/stores/src/lib/utils";
import { CelerumAddLocationLabel } from "../../../../../../../../common/ui/src/lib/components/celerum-add-location-label/celerum-add-location-label.component";
import {
	CelerumFormDatePicker,
	CelerumFormDateTimePicker,
	CelerumFormDropDownList,
	CelerumFormInput,
	CelerumFormMultiSelect,
	CelerumFormTextArea,
	CelerumFormUploadInput,
	CelerumSuggestionsInput,
} from "../../../../../../../../common/ui/src/lib/components/celerum-form-elements/celerum-form-elements.component";
import { suggestionsSearchChange } from "../../../../../../../../common/utils/src/lib/helpers/suggestions-search-change.helpers";
import { useInputChange } from "../../../../../../../../common/utils/src/lib/hooks/use-input-change.hooks";
import {
	noNegativeValidator,
	requiredValidator,
} from "../../../../../../../../common/utils/src/lib/validators/validators";
import { fetchDriversAction } from "../../../../../../../../drivers/data-access/src/lib/drivers.slice";
import { fetchLocationsAction } from "../../../../../../../../locations/data-access/src/lib/locations.slice";
import { fetchSubcontractorsAction } from "../../../../../../../../subcontractors/data-access/src/lib/subcontractors.slice";
import { fetchTrailerTypesAction } from "../../../../../../../../trailers/data-access/src/lib/trailer-types.slice";
import { fetchTrailersAction } from "../../../../../../../../trailers/data-access/src/lib/trailers.slice";
import { fetchTruckTypesAction } from "../../../../../../../../trucks/data-access/src/lib/truck-types.slice";
import { fetchTrucksAction } from "../../../../../../../../trucks/data-access/src/lib/trucks.slice";
import styles from "../../leg-form.module.scss";

interface GoodsSummaryProps {
	id: number;
	name: string;
	collectionDate: Date | null;
	collectionLocation: ILocation | null;
	deliveryDate: Date | null;
	deliveryLocation: ILocation | null;
}

interface OperationFieldsProps {
	formData: IOperationLeg | undefined;
	goods: GoodsSummaryProps[];
	selectedType: IBase | null;
	selectedChauffeur: string;
	updateMode: boolean;
	isLegTransferred: boolean;
	formRenderProps: FormRenderProps;
	parentType?: ParentType;
	setGoodsIds: Dispatch<SetStateAction<number[]>>;
	setShowLocationForm: Dispatch<SetStateAction<boolean>>;
	setSelectedChauffeur: Dispatch<SetStateAction<string>>;
	setFormData: Dispatch<SetStateAction<IOperationLeg | undefined>>;
	setFieldToUpdate: Dispatch<SetStateAction<string | undefined>>;
}

export const OperationFields = ({
	goods,
	formData,
	selectedType,
	selectedChauffeur,
	updateMode,
	isLegTransferred,
	parentType,
	setGoodsIds,
	setSelectedChauffeur,
	setShowLocationForm,
	setFormData,
	setFieldToUpdate,
	formRenderProps,
}: OperationFieldsProps) => {
	const handleInputChange = useInputChange();
	const dispatch = useAppDispatch();
	const dispatchWithNotifications = useAppDispatchWithNotifications();

	const [cost, setCost] = useState<number | undefined>(undefined);

	const costValidator: FieldValidatorType | FieldValidatorType[] | undefined =
		cost !== undefined && cost < 0 ? noNegativeValidator : undefined;

	const currencyValidator:
		| FieldValidatorType
		| FieldValidatorType[]
		| undefined =
		cost !== undefined && cost >= 0 ? requiredValidator : undefined;

	const {
		truckTypes,
		trucks,
		trailerTypes,
		trailers,
		currencies,
		drivers,
		subcontractors,
		businessUnits,
		currentBusinessUnit,
		locations,
	} = useAppSelector((state) => ({
		truckTypes: state.truckTypes.data,
		trucks: state.trucks.data,
		trailerTypes: state.trailerTypes.data,
		trailers: state.trailers.data,
		locations: state.locations.data,
		currencies: state.currencies.data,
		drivers: state.drivers.data,
		subcontractors: state.subcontractors.data,
		businessUnits: state.businessUnits.data,
		currentBusinessUnit: state.authentication.currentBusinessUnit,
	}));

	const renderedTrucks = useMemo(
		() =>
			trucks.map((item) => ({
				id: item.id,
				name: `${item.name} - ${item.registration} ${
					item.subcontractor?.name ? `(${item.subcontractor.name})` : ""
				}`,
			})),
		[trucks],
	);

	const renderedTrailers = useMemo(
		() =>
			trailers.map((item) => ({
				id: item.id,
				name: `${item.name} ${
					item.subcontractor?.name ? `(${item.subcontractor.name})` : ""
				}`,
			})),
		[trailers],
	);

	const renderedDrivers = useMemo(
		() =>
			drivers
				.map((item) => ({
					id: item.id,
					name: `${item.fullName} ${
						item.subcontractor?.name ? `(${item.subcontractor.name})` : ""
					}`,
					truck: item.truck || null,
					trailer: item.trailer || null,
				}))
				.filter((item) => item.id !== -1),
		[drivers],
	);

	const renderedSubcontractors = useMemo(
		() =>
			subcontractors
				.map((item) => ({
					id: item.id,
					name: item.name,
				}))
				.filter((item) => item.id !== -1),
		[subcontractors],
	);

	const renderedBusinessUnits = useMemo(
		() =>
			businessUnits
				.map((item) => ({
					id: item.id,
					name: item.name,
				}))
				.filter(
					(item) => item.id !== -1 && item.id !== currentBusinessUnit?.id,
				),
		[businessUnits, currentBusinessUnit],
	);

	const handleSearchLocationsChange = suggestionsSearchChange({
		dispatchFunction: dispatch,
		searchFields: ["name", "address"],
		reduxAction: fetchLocationsAction,
	});

	const handleSearchDriversChange = suggestionsSearchChange({
		dispatchFunction: dispatch,
		searchFields: ["fullName"],
		reduxAction: fetchDriversAction,
	});

	const handleSearchTrucksChange = suggestionsSearchChange({
		dispatchFunction: dispatch,
		searchFields: ["name", "registration"],
		reduxAction: fetchTrucksAction,
	});

	const handleSearchTrailersChange = suggestionsSearchChange({
		dispatchFunction: dispatch,
		searchFields: ["name"],
		reduxAction: fetchTrailersAction,
	});

	const handleSearchSubcontractorChange = suggestionsSearchChange({
		dispatchFunction: dispatch,
		searchFields: ["name"],
		reduxAction: fetchSubcontractorsAction,
	});

	const updateFields = (
		event: MultiSelectChangeEvent,
		fieldsToUpdate: { name: string; value: unknown }[],
	) => {
		for (const field of fieldsToUpdate) {
			formRenderProps.onChange(field.name, { value: field.value });
			handleInputChange(event, field.name, setFormData, field.value);
		}
	};

	const onGoodsDropdownChange = (event: MultiSelectChangeEvent) => {
		const selectedGoods = event.target.value;
		setGoodsIds(selectedGoods.map((item: IBase) => item.id));

		if (selectedGoods.length === 1) {
			const goodsItem = goods.find((item) => item.id === selectedGoods[0].id);
			handleInputChange(event, "goods", setFormData);

			if (goodsItem) {
				const {
					collectionLocation,
					collectionDate,
					deliveryLocation,
					deliveryDate,
				} = goodsItem;
				const fieldsToUpdate = [
					{ name: "collectionLocation", value: collectionLocation },
					{ name: "collectionDate", value: collectionDate },
					{ name: "deliveryLocation", value: deliveryLocation },
					{ name: "deliveryDate", value: deliveryDate },
				];
				updateFields(event, fieldsToUpdate);
			}
		} else if (!selectedGoods.length) {
			const fieldsToUpdate = [
				{ name: "collectionLocation", value: null },
				{ name: "collectionDate", value: null },
				{ name: "deliveryLocation", value: null },
				{ name: "deliveryDate", value: null },
			];
			updateFields(event, fieldsToUpdate);
		}
	};

	useEffect(() => {
		dispatchWithNotifications({
			action: fetchTruckTypesAction,
			payload: {},
			errorMessage: "Could not fetch truck types.",
		});
		dispatchWithNotifications({
			action: fetchTrailerTypesAction,
			payload: {},
			errorMessage: "Could not fetch trailer types.",
		});
		dispatchWithNotifications({
			action: fetchTrailersAction,
			payload: { page: 1, pageSize: DEFAULT_PAGE_SIZE },
			errorMessage: "Could not fetch trailers.",
		});
		dispatchWithNotifications({
			action: fetchDriversAction,
			payload: { page: 1, pageSize: DEFAULT_PAGE_SIZE },
			errorMessage: "Could not fetch drivers.",
		});
		dispatchWithNotifications({
			action: fetchTrucksAction,
			payload: { page: 1, pageSize: DEFAULT_PAGE_SIZE },
			errorMessage: "Could not fetch trucks.",
		});
		dispatchWithNotifications({
			action: fetchSubcontractorsAction,
			payload: { page: 1, pageSize: DEFAULT_PAGE_SIZE },
			errorMessage: "Could not fetch subcontractors.",
		});
	}, [dispatchWithNotifications]);

	return (
		<>
			<div>
				{parentType === ParentType.Job && (
					<Field
						id="goods"
						label="Goods"
						name="goods"
						dataItemKey="id"
						textField="name"
						data={goods}
						onChange={onGoodsDropdownChange}
						component={CelerumFormMultiSelect}
						validator={requiredValidator}
						required
					/>
				)}
				<Field
					id="collectionLocation"
					dataItemKey="id"
					label="Collection Location"
					textField="nameAndAddress"
					name="collectionLocation"
					component={CelerumSuggestionsInput}
					handleSearchChange={handleSearchLocationsChange}
					data={locations}
					required={
						!!(
							selectedType?.name === COLLECTION_LEG.name ||
							selectedType?.name === COLLECT_DELIVER_LEG.name
						)
					}
					validator={
						selectedType?.name === COLLECTION_LEG.name ||
						selectedType?.name === COLLECT_DELIVER_LEG.name
							? requiredValidator
							: undefined
					}
					onFooterClick={() => {
						setShowLocationForm(true);
						setFieldToUpdate("collectionLocation");
					}}
					footer={<CelerumAddLocationLabel />}
					onChange={(event) =>
						handleInputChange(event, "collectionLocation", setFormData)
					}
				/>
				<Field
					id="collectionDate"
					name="collectionDate"
					label="Collection Date"
					component={CelerumFormDateTimePicker}
					required={
						!!(
							selectedType?.name === COLLECTION_LEG.name ||
							selectedType?.name === COLLECT_DELIVER_LEG.name ||
							selectedChauffeur === BUSINESS_UNITS_FEATURE_KEY
						)
					}
					validator={
						selectedType?.name === COLLECTION_LEG.name ||
						selectedType?.name === COLLECT_DELIVER_LEG.name ||
						selectedChauffeur === BUSINESS_UNITS_FEATURE_KEY
							? requiredValidator
							: undefined
					}
					onChange={(event) =>
						handleInputChange(event, "collectionDate", setFormData)
					}
				/>
				<Field
					id="deliveryLocation"
					dataItemKey="id"
					label="Delivery Location"
					textField="nameAndAddress"
					name="deliveryLocation"
					handleSearchChange={handleSearchLocationsChange}
					component={CelerumSuggestionsInput}
					data={locations}
					required={
						!!(
							selectedType?.name === DELIVERY_LEG.name ||
							selectedType?.name === COLLECT_DELIVER_LEG.name
						)
					}
					validator={
						selectedType?.name === DELIVERY_LEG.name ||
						selectedType?.name === COLLECT_DELIVER_LEG.name
							? requiredValidator
							: undefined
					}
					onFooterClick={() => {
						setShowLocationForm(true);
						setFieldToUpdate("deliveryLocation");
					}}
					footer={<CelerumAddLocationLabel />}
					onChange={(event) =>
						handleInputChange(event, "deliveryLocation", setFormData)
					}
				/>
				<Field
					id="deliveryDate"
					name="deliveryDate"
					label="Delivery Date"
					component={CelerumFormDateTimePicker}
					required={
						!!(
							selectedType?.name === DELIVERY_LEG.name ||
							selectedType?.name === COLLECT_DELIVER_LEG.name ||
							selectedChauffeur === BUSINESS_UNITS_FEATURE_KEY
						)
					}
					validator={
						selectedType?.name === DELIVERY_LEG.name ||
						selectedType?.name === COLLECT_DELIVER_LEG.name ||
						selectedChauffeur === BUSINESS_UNITS_FEATURE_KEY
							? requiredValidator
							: undefined
					}
					onChange={(event) =>
						handleInputChange(event, "deliveryDate", setFormData)
					}
				/>
			</div>
			<div>
				<Field
					id="truckType"
					dataItemKey="id"
					label="Truck Type"
					textField="name"
					name="truckType"
					component={CelerumFormDropDownList}
					data={truckTypes}
					onChange={(event) =>
						handleInputChange(event, "truckType", setFormData)
					}
				/>
				<Field
					id="trailerType"
					dataItemKey="id"
					label="Trailer Type"
					textField="name"
					name="trailerType"
					component={CelerumFormDropDownList}
					data={trailerTypes}
					onChange={(event) =>
						handleInputChange(event, "trailerType", setFormData)
					}
				/>
				<div className={styles.chaufferContainer}>
					<label
						className={styles.checkmark}
						style={{ color: isLegTransferred ? "#9D9DAB" : "" }}
					>
						Assign
						<input
							type="radio"
							checked={selectedChauffeur === DRIVERS_FEATURE_KEY}
							name="chauffeur"
							disabled={isLegTransferred}
							onChange={() => {
								setSelectedChauffeur(DRIVERS_FEATURE_KEY);
							}}
						/>
						<span className={styles.checkmark__radio} />
					</label>
					<label
						className={styles.checkmark}
						style={{ color: isLegTransferred ? "#9D9DAB" : "" }}
					>
						Transfer
						<input
							type="radio"
							checked={selectedChauffeur === BUSINESS_UNITS_FEATURE_KEY}
							name="chauffeur"
							disabled={isLegTransferred}
							onChange={() => {
								setSelectedChauffeur(BUSINESS_UNITS_FEATURE_KEY);
								setFormData((prevFormData) => {
									if (!prevFormData) return;
									return {
										...prevFormData,
										driver: null,
										truck: null,
										trailer: null,
										subcontractor: null,
									};
								});

								formRenderProps.onChange("driver", { value: null });
								formRenderProps.onChange("truck", { value: null });
								formRenderProps.onChange("trailer", { value: null });
								formRenderProps.onChange("subcontractor", { value: null });
							}}
						/>
						<span className={styles.checkmark__radio} />
					</label>
				</div>
				{selectedChauffeur === DRIVERS_FEATURE_KEY && (
					<>
						<Field
							id="driver"
							dataItemKey="id"
							label="Driver"
							textField="name"
							name="driver"
							handleSearchChange={handleSearchDriversChange}
							component={CelerumSuggestionsInput}
							data={renderedDrivers}
							disabled={isLegTransferred}
							onChange={async (event) => {
								const { value: driver } = event.target;
								setFormData((prevFormData) => {
									if (!prevFormData) return;
									return { ...prevFormData, driver: driver };
								});

								if (!driver) return;

								const result = (
									await jobApi.fleet.fleetFindList({ DriverId: driver.id })
								)?.data;

								if (formData?.truck?.id == null && result?.truck) {
									setFormData((prevFormData) => {
										if (!prevFormData) return;
										return { ...prevFormData, truck: result.truck };
									});
									formRenderProps.onChange("truck", { value: result.truck });
								}

								if (formData?.trailer?.id == null && result?.trailer) {
									setFormData((prevFormData) => {
										if (!prevFormData) return;
										return { ...prevFormData, trailer: result.trailer };
									});
									formRenderProps.onChange("trailer", {
										value: result.trailer,
									});
								}
							}}
						/>
						<Field
							id="truck"
							dataItemKey="id"
							label="Truck"
							textField="name"
							name="truck"
							handleSearchChange={handleSearchTrucksChange}
							component={CelerumSuggestionsInput}
							data={renderedTrucks}
							disabled={isLegTransferred}
							onChange={async (event) => {
								const { value: truck } = event.target;
								setFormData((prevFormData) => {
									if (!prevFormData) return;
									return {
										...prevFormData,
										truck: truck,
									};
								});
								if (!truck) return;

								const result = (
									await jobApi.fleet.fleetFindList({ TruckId: truck.id })
								)?.data;

								if (formData?.driver?.id == null && result.driver) {
									const driver = {
										...result.driver,
										name: `${result.driver.firstName} ${result.driver.lastName}`,
									} as IDriver;
									setFormData((prevFormData) => {
										if (!prevFormData) return;
										return {
											...prevFormData,
											driver: driver,
										};
									});
									formRenderProps.onChange("driver", { value: driver });
								}

								if (formData?.trailer?.id == null && result.trailer) {
									setFormData((prevFormData) => {
										if (!prevFormData) return;
										return {
											...prevFormData,
											trailer: result.trailer,
										};
									});
									formRenderProps.onChange("trailer", {
										value: result.trailer,
									});
								}
								console.log("formData", result);
								if (result.subcontractor) {
									setFormData((prevFormData) => {
										if (!prevFormData) return;
										return {
											...prevFormData,
											subcontractor:
												result.subcontractor as unknown as ISubcontractor,
										};
									});

									const fieldsToUpdate = [
										{ name: "subcontractor", value: result.subcontractor },
										{ name: "supplierInvoice", value: SUPPLIER_INVOICES[1] },
									];

									updateFields(event, fieldsToUpdate);
								}
							}}
						/>
						<Field
							id="trailer"
							dataItemKey="id"
							label="Trailer"
							textField="name"
							name="trailer"
							handleSearchChange={handleSearchTrailersChange}
							component={CelerumSuggestionsInput}
							data={renderedTrailers}
							disabled={isLegTransferred}
							onChange={async (event) => {
								const { value: trailer } = event.target;
								setFormData((prevFormData) => {
									if (!prevFormData) return;
									return {
										...prevFormData,
										trailer: trailer,
									};
								});
								if (!trailer) return;

								const result = (
									await jobApi.fleet.fleetFindList({ TrailerId: trailer.id })
								)?.data;

								if (formData?.driver?.id == null && result.driver) {
									const driver = {
										...result.driver,
										name: `${result.driver.firstName} ${result.driver.lastName}`,
									} as IDriver;

									setFormData((prevFormData) => {
										if (!prevFormData) return;
										return {
											...prevFormData,
											driver: driver,
										};
									});
									formRenderProps.onChange("driver", { value: driver });
								}

								if (formData?.truck?.id == null && result.truck) {
									setFormData((prevFormData) => {
										if (!prevFormData) return;
										return {
											...prevFormData,
											truck: result.truck,
										};
									});
									formRenderProps.onChange("truck", { value: result.truck });
								}
							}}
						/>
						<Field
							id="subcontractor"
							dataItemKey="name"
							label="Subcontractor"
							textField="name"
							name="subcontractor"
							handleSearchChange={handleSearchSubcontractorChange}
							component={CelerumSuggestionsInput}
							data={renderedSubcontractors}
							disabled={isLegTransferred}
							onChange={async (event) => {
								const { value: subcontractor } = event.target;
								setFormData((prevFormData) => {
									if (!prevFormData) return;
									return {
										...prevFormData,
										subcontractor: subcontractor,
									};
								});

								// NOTE: ignore if the values was set manually to other than the first two values
								if (
									formData?.supplierInvoice !== SUPPLIER_INVOICES[0] &&
									formData?.supplierInvoice !== SUPPLIER_INVOICES[1]
								) {
									return;
								}

								let valueSupplierInvoice = SUPPLIER_INVOICES[1];
								if (!subcontractor) {
									valueSupplierInvoice = SUPPLIER_INVOICES[0];
								}

								const fieldsToUpdate = [
									{ name: "supplierInvoice", value: valueSupplierInvoice },
								];

								updateFields(event, fieldsToUpdate);
							}}
						/>
					</>
				)}
				{selectedChauffeur === BUSINESS_UNITS_FEATURE_KEY && (
					<Field
						id="businessUnit"
						dataItemKey="id"
						label="Business Unit"
						textField="name"
						name="businessUnit"
						component={CelerumFormDropDownList}
						data={renderedBusinessUnits}
						disabled={isLegTransferred}
						onChange={(event) =>
							handleInputChange(event, "businessUnit", setFormData)
						}
						validator={requiredValidator}
						required={selectedChauffeur === BUSINESS_UNITS_FEATURE_KEY}
					/>
				)}
			</div>
			<div>
				<div className={styles.currency}>
					<div className={styles.currency__cost}>
						<Field
							id="cost"
							label="Cost"
							name="cost"
							placeholder="0.00"
							component={CelerumFormInput}
							onChange={(event) => {
								const value = Number.parseFloat(event.target.value);
								setCost(Number.isNaN(value) ? undefined : value);
								handleInputChange(event, "cost", setFormData);
							}}
							type="number"
							validator={costValidator}
							focused="true"
						/>
					</div>
					<div className={styles.currency__code}>
						<Field
							id="currency"
							dataItemKey="id"
							label="Currency"
							textField="code"
							name="currency"
							onChange={(event) =>
								handleInputChange(event, "currency", setFormData)
							}
							component={CelerumFormDropDownList}
							data={currencies}
							validator={currencyValidator}
							required={cost !== undefined && cost >= 0}
						/>
					</div>
				</div>
				<Field
					id="supplierInvoiceNumber"
					label="Supplier Invoice Number"
					name="supplierInvoiceNumber"
					maxLength={500}
					component={CelerumFormInput}
					onChange={(event) =>
						handleInputChange(event, "supplierInvoiceNumber", setFormData)
					}
				/>
				<Field
					id="supplierInvoice"
					label="Supplier Invoice"
					name="supplierInvoice"
					dataItemKey="id"
					textField="name"
					component={CelerumFormDropDownList}
					data={SUPPLIER_INVOICES}
					defaultValue={SUPPLIER_INVOICES[0]}
					onChange={(event) =>
						handleInputChange(event, "supplierInvoice", setFormData)
					}
				/>
				<Field
					id="supplierInvoiceDate"
					label="Supplier Invoice Date"
					name="supplierInvoiceDate"
					component={CelerumFormDatePicker}
					onChange={(event) =>
						handleInputChange(event, "supplierInvoiceDate", setFormData)
					}
				/>
				<Field
					id="note"
					label="Notes"
					name="notes"
					maxLength={500}
					component={CelerumFormTextArea}
					onChange={(event) => handleInputChange(event, "notes", setFormData)}
					type="text"
				/>
				{!updateMode && (
					<Field
						label="Upload Documents"
						id="files"
						name="files"
						component={CelerumFormUploadInput}
						onChange={(event) => {
							setFormData((prev) => {
								if (prev) {
									return { ...prev, files: event.value };
								}
							});
						}}
					/>
				)}
			</div>
		</>
	);
};
