import { useEffect, useMemo, useState } from "react";
import { fetchAttachmentUriAction } from "../../../../../../attachments/data-access/src/lib/attachments.slice";
import { SUPPLIER_INVOICES } from "../../../../../../common/models/src/lib/constants/invoice.constants";
import { LEG_STATUS_MAPPINGS } from "../../../../../../common/models/src/lib/constants/leg.constants";
import { NOT_AVAILABLE } from "../../../../../../common/models/src/lib/constants/messages.constants";
import { LegType } from "../../../../../../common/models/src/lib/enums/leg.enum";
import { ModalSize } from "../../../../../../common/models/src/lib/enums/modal.enums";
import type { IBase } from "../../../../../../common/models/src/lib/interfaces/base.interface";
import type { IDocument } from "../../../../../../common/models/src/lib/interfaces/document.interface";
import type { ILegData } from "../../../../../../common/models/src/lib/interfaces/leg.interface";
import type {
	AllLegs,
	AnyLeg,
} from "../../../../../../common/models/src/lib/types/leg.type";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../../common/stores/src/lib/utils";
import { CelerumViewDetailsButton } from "../../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import { CelerumDocumentsForm } from "../../../../../../common/ui/src/lib/components/celerum-documents-form/celerum-documents-form.component";
import { CelerumInteractiveStatus } from "../../../../../../common/ui/src/lib/components/celerum-interactive-status/celerum-interactive-status.component";
import { CelerumLoader } from "../../../../../../common/ui/src/lib/components/celerum-loader/celerum-loader.components";
import { CelerumModal } from "../../../../../../common/ui/src/lib/components/celerum-modal/celerum-modal.component";
import { CelerumTag } from "../../../../../../common/ui/src/lib/components/celerum-tag/celerum-tag.component";
import { getFormattedCurrency } from "../../../../../../common/utils/src/lib/helpers/currency.helpers";
import { formatDate } from "../../../../../../common/utils/src/lib/helpers/date.helpers";
import { renderLegTypeTitle } from "../../../../../../common/utils/src/lib/helpers/leg.helpers";
import { fetchLegsByJobIdAction } from "../../../../../../legs/data-access/src/lib/legs.slice";
import styles from "./legs-container.module.scss";

export const LegsContainer = () => {
	const dispatchWithNotifications = useAppDispatchWithNotifications();
	const dispatch = useAppDispatch();

	const { job } = useAppSelector((state) => state.jobs);
	const { data, loading } = useAppSelector((state) => state.legs);

	const [selectedLeg, setSelectedLeg] = useState<AnyLeg | null>(null);
	const [showModal, setShowModal] = useState<{
		pod: boolean;
		documents: boolean;
	}>({ pod: false, documents: false });

	const renderedLegs = useMemo(() => {
		const listOfLegs = Object.keys(data).reduce((accumulator, key) => {
			const array = data[key as keyof ILegData];
			return array ? accumulator.concat(array) : accumulator;
		}, [] as AllLegs);

		return listOfLegs.map((leg) => {
			const documents: IDocument[] = [];
			const podDocuments: IDocument[] = [];

			for (const document of leg.documents) {
				document.isPod ? podDocuments.push(document) : documents.push(document);
			}

			return { ...leg, documents, podDocuments };
		});
	}, [data]);

	const calculateNumberOfDays = (
		storageStartDate: string | Date,
		storageEndDate: string | Date,
	) =>
		Math.ceil(
			(new Date(storageEndDate).getTime() -
				new Date(storageStartDate).getTime()) /
				(1000 * 60 * 60 * 24),
		);

	const handleDownloadLegDocument = (attachmentId: number | string) => {
		dispatch(fetchAttachmentUriAction(attachmentId));
	};

	const closeModal = () => {
		setShowModal({ pod: false, documents: false });
		setSelectedLeg(null);
	};

	const renderViewDocumentsButton = (
		leg: AnyLeg,
		showHorizontalLine = false,
	) => (
		<>
			<div className={styles.item__content}>
				<span className={styles.item__content__label}>View Documents</span>
				<div className={styles.item__content__tags}>
					<CelerumViewDetailsButton
						onClick={() => {
							setShowModal((prev) => ({ ...prev, documents: true }));
							setSelectedLeg(leg);
						}}
						style={{ height: "25px", fontSize: "10px" }}
						title={`View Documents (${leg.documents.length})`}
					/>
				</div>
			</div>
			{showHorizontalLine && <div className={styles.item__content__break} />}
		</>
	);

	const renderViewPodDocumentsButton = (leg: AnyLeg) => (
		<div className={styles.item__content}>
			<span className={styles.item__content__label}>View POD Documents</span>
			<div className={styles.item__content__tags}>
				<CelerumViewDetailsButton
					onClick={() => {
						setShowModal((prev) => ({ ...prev, pod: true }));
						setSelectedLeg(leg);
					}}
					style={{ height: "25px", fontSize: "10px" }}
					title={`View POD Documents (${leg.podDocuments.length})`}
				/>
			</div>
		</div>
	);

	const renderItemContent = (
		label: string,
		value: string | number | IBase[],
		showHorizontalLine = true,
	) => (
		<>
			<div className={styles.item__content}>
				<span className={styles.item__content__label}>{label}</span>
				{typeof value === "object" ? (
					<div className={styles.item__content__tags}>
						{value.map((item: IBase) => (
							<CelerumTag name={item.name} key={item.id} />
						))}
					</div>
				) : (
					<span className={styles.item__content__value}>{value}</span>
				)}
			</div>
			{showHorizontalLine && <div className={styles.item__content__break} />}
		</>
	);

	const renderItem = (leg: AnyLeg) => {
		let element: JSX.Element | null = null;

		switch (leg.type) {
			case LegType.Delivery:
			case LegType.Segment:
			case LegType.Collection:
			case LegType.ContainerDropOff:
			case LegType.ContainerPickUp:
			case LegType.ContainerGoodsCollection:
			case LegType.ContainerGoodsDelivery:
			case LegType.LoadAndLash:
			case LegType.Devan:
			case LegType.CollectDeliver: {
				element = (
					<>
						{renderItemContent(
							"Collection Location",
							leg.collectionLocation?.name ?? NOT_AVAILABLE,
						)}
						{renderItemContent(
							"Collection Date/Time",
							leg.collectionDate
								? formatDate(new Date(leg.collectionDate))
								: NOT_AVAILABLE,
						)}
						{renderItemContent(
							"Delivery Location",
							leg.deliveryLocation?.name ?? NOT_AVAILABLE,
						)}
						{renderItemContent(
							"Delivery Date/Time",
							leg.deliveryDate
								? formatDate(new Date(leg.deliveryDate))
								: NOT_AVAILABLE,
						)}
						{renderItemContent("Driver", leg.driver?.name ?? NOT_AVAILABLE)}
						{renderItemContent(
							"Driver Phone",
							leg.driver?.phones.length
								? leg.driver.phones[0] ?? NOT_AVAILABLE
								: NOT_AVAILABLE,
						)}
						{renderItemContent(
							"Truck Type",
							leg.truckType?.name ?? NOT_AVAILABLE,
						)}
						{renderItemContent(
							"Trailer Type",
							leg.trailerType?.name ?? NOT_AVAILABLE,
						)}
						{renderItemContent(
							"Price",
							leg.cost
								? getFormattedCurrency(leg.cost, leg.currency.code)
								: NOT_AVAILABLE,
						)}
						{renderItemContent("Goods", leg.goods ?? NOT_AVAILABLE)}
						{renderItemContent(
							"Supplier Invoice",
							leg.supplierInvoice
								? SUPPLIER_INVOICES[leg.supplierInvoice]?.name ?? NOT_AVAILABLE
								: NOT_AVAILABLE,
						)}
						{renderItemContent(
							"Supplier Invoice Number",
							leg?.supplierInvoiceNumber && leg.supplierInvoiceNumber !== ""
								? leg?.supplierInvoiceNumber
								: NOT_AVAILABLE,
						)}
						{renderItemContent(
							"Supplier Invoice Date",
							leg.supplierInvoiceDate
								? formatDate(new Date(leg.supplierInvoiceDate))
								: NOT_AVAILABLE,
						)}
						{renderItemContent(
							"Notes",
							leg.notes ?? NOT_AVAILABLE,
							leg.documents.length > 0,
						)}
						{leg.documents?.length
							? renderViewDocumentsButton(leg, leg.podDocuments?.length > 0)
							: null}
						{leg.podDocuments?.length
							? renderViewPodDocumentsButton(leg)
							: null}
					</>
				);
				break;
			}
			case LegType.Storage: {
				element = (
					<>
						{renderItemContent(
							"Storage Location",
							leg.storageLocation ?? NOT_AVAILABLE,
						)}
						{renderItemContent(
							"Storage Start Date",
							leg.storageStartDate
								? formatDate(new Date(leg.storageStartDate))
								: NOT_AVAILABLE,
						)}
						{renderItemContent(
							"Storage End Date",
							leg.storageEndDate
								? formatDate(new Date(leg.storageEndDate))
								: NOT_AVAILABLE,
						)}
						{renderItemContent(
							"Storage Days",
							calculateNumberOfDays(leg.storageStartDate, leg.storageEndDate),
						)}
						{renderItemContent(
							"Price",
							leg.cost
								? getFormattedCurrency(leg.cost, leg.currency.code)
								: NOT_AVAILABLE,
						)}
						{renderItemContent("Goods", leg.goods ?? NOT_AVAILABLE)}
						{renderItemContent(
							"Supplier Invoice",
							leg.supplierInvoice
								? SUPPLIER_INVOICES[leg.supplierInvoice]?.name ?? NOT_AVAILABLE
								: NOT_AVAILABLE,
						)}
						{renderItemContent(
							"Supplier Invoice Number",
							leg?.supplierInvoiceNumber && leg.supplierInvoiceNumber !== ""
								? leg?.supplierInvoiceNumber
								: NOT_AVAILABLE,
						)}
						{renderItemContent(
							"Supplier Invoice Date",
							leg.supplierInvoiceDate
								? formatDate(new Date(leg.supplierInvoiceDate))
								: NOT_AVAILABLE,
						)}
						{renderItemContent(
							"Notes",
							leg.notes ?? NOT_AVAILABLE,
							leg.documents.length > 0,
						)}
						{leg.documents.length ? renderViewDocumentsButton(leg) : null}
					</>
				);
				break;
			}
			case LegType.Ferry: {
				element = (
					<>
						{renderItemContent("Ferry Route", leg.ferryRoute ?? NOT_AVAILABLE)}
						{renderItemContent(
							"Ferry Sailing date",
							leg.ferrySailingDate
								? formatDate(new Date(leg.ferrySailingDate))
								: NOT_AVAILABLE,
						)}
						{renderItemContent(
							"Ferry Reference Number",
							leg.ferryReferenceNumber ?? NOT_AVAILABLE,
						)}
						{renderItemContent(
							"Price",
							leg.cost
								? getFormattedCurrency(leg.cost, leg.currency.code)
								: NOT_AVAILABLE,
						)}
						{renderItemContent("Goods", leg.goods ?? NOT_AVAILABLE)}
						{renderItemContent(
							"Supplier Invoice",
							leg.supplierInvoice
								? SUPPLIER_INVOICES[leg.supplierInvoice]?.name ?? NOT_AVAILABLE
								: NOT_AVAILABLE,
						)}
						{renderItemContent(
							"Supplier Invoice Number",
							leg?.supplierInvoiceNumber && leg.supplierInvoiceNumber !== ""
								? leg?.supplierInvoiceNumber
								: NOT_AVAILABLE,
						)}
						{renderItemContent(
							"Supplier Invoice Date",
							leg.supplierInvoiceDate
								? formatDate(new Date(leg.supplierInvoiceDate))
								: NOT_AVAILABLE,
						)}
						{renderItemContent(
							"Notes",
							leg.notes ?? NOT_AVAILABLE,
							leg.documents.length > 0,
						)}
						{leg.documents.length ? renderViewDocumentsButton(leg) : null}
					</>
				);
				break;
			}
			case LegType.ClearCustoms: {
				element = (
					<>
						{renderItemContent(
							"UCR",
							leg.ucr && leg.ucr !== "" ? leg.ucr : NOT_AVAILABLE,
						)}
						{renderItemContent(
							"MRN",
							leg.mrn && leg.mrn !== "" ? leg.mrn : NOT_AVAILABLE,
						)}
						{renderItemContent(
							"T1",
							leg.t1 && leg.t1 !== "" ? leg.t1 : NOT_AVAILABLE,
						)}
						{renderItemContent(
							"Clearance Location",
							leg.clearanceLocation && leg.clearanceLocation !== ""
								? leg.clearanceLocation
								: NOT_AVAILABLE,
						)}
						{renderItemContent(
							"Clearance Date",
							leg.clearanceDate
								? formatDate(new Date(leg.clearanceDate))
								: NOT_AVAILABLE,
						)}
						{renderItemContent(
							"Price",
							leg.cost
								? getFormattedCurrency(leg.cost, leg.currency.code)
								: NOT_AVAILABLE,
						)}
						{renderItemContent("Goods", leg.goods ?? NOT_AVAILABLE)}
						{renderItemContent(
							"Supplier Invoice",
							leg.supplierInvoice
								? SUPPLIER_INVOICES[leg.supplierInvoice]?.name ?? NOT_AVAILABLE
								: NOT_AVAILABLE,
						)}
						{renderItemContent(
							"Supplier Invoice Number",
							leg.supplierInvoiceNumber && leg.supplierInvoiceNumber !== ""
								? leg.supplierInvoiceNumber
								: NOT_AVAILABLE,
						)}
						{renderItemContent(
							"Supplier Invoice Date",
							leg.supplierInvoiceDate
								? formatDate(new Date(leg.supplierInvoiceDate))
								: NOT_AVAILABLE,
						)}
						{renderItemContent(
							"Notes",
							leg.notes ?? NOT_AVAILABLE,
							leg.documents.length > 0,
						)}
						{leg.documents.length ? renderViewDocumentsButton(leg) : null}
					</>
				);
				break;
			}
			default:
				element = (
					<>
						{renderItemContent(
							"Price",
							leg.cost
								? getFormattedCurrency(leg.cost, leg.currency.code)
								: NOT_AVAILABLE,
						)}
						{renderItemContent("Goods", leg.goods ?? NOT_AVAILABLE)}
						{renderItemContent(
							"Supplier Invoice",
							leg.supplierInvoice
								? SUPPLIER_INVOICES[leg.supplierInvoice]?.name ?? NOT_AVAILABLE
								: NOT_AVAILABLE,
						)}
						{renderItemContent(
							"Supplier Invoice Number",
							leg.supplierInvoiceNumber && leg.supplierInvoiceNumber !== ""
								? leg.supplierInvoiceNumber
								: NOT_AVAILABLE,
						)}
						{renderItemContent(
							"Supplier Invoice Date",
							leg.supplierInvoiceDate
								? formatDate(new Date(leg.supplierInvoiceDate))
								: NOT_AVAILABLE,
						)}
						{renderItemContent(
							"Notes",
							leg.notes ?? NOT_AVAILABLE,
							leg.documents.length > 0,
						)}
						{leg.documents.length ? renderViewDocumentsButton(leg) : null}
					</>
				);
				break;
		}

		return (
			<div className={styles.item} key={leg.id}>
				<div className={styles.item__header}>
					<div className={styles.item__header__title}>
						{renderLegTypeTitle(leg)}
					</div>
					<CelerumInteractiveStatus
						status={leg.status}
						statusMappings={LEG_STATUS_MAPPINGS}
						isInteractive={false}
					/>
				</div>
				{element}
			</div>
		);
	};

	useEffect(() => {
		if (job?.id) {
			dispatchWithNotifications({
				action: fetchLegsByJobIdAction,
				payload: job?.id,
				errorMessage: "Could not fetch legs.",
			});
		}
	}, [job?.id, dispatchWithNotifications]);

	return (
		<>
			<div className={styles.container}>
				<div className={styles.header}>
					<span className={styles.header__title}>Legs</span>
				</div>
				{loading ? (
					<div className={styles.loader}>
						<CelerumLoader visible />
					</div>
				) : (
					<div className={styles.content}>
						{renderedLegs.map((leg: AnyLeg) => renderItem(leg))}
					</div>
				)}
			</div>
			<CelerumModal
				title="Documents"
				visible={showModal.documents || showModal.pod}
				width={ModalSize.Small}
				toggleDialog={closeModal}
			>
				<CelerumDocumentsForm
					entityId={selectedLeg?.id}
					onClose={closeModal}
					documents={
						showModal.pod
							? selectedLeg?.podDocuments ?? []
							: selectedLeg?.documents ?? []
					}
					handleDownloadAttachment={handleDownloadLegDocument}
					isInteractive={false}
				/>
			</CelerumModal>
		</>
	);
};
